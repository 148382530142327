import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../content/privacy/minors.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { defaultSectionWidth, largeText, smallSubtitle, smallText } from '../../../styles/dimensions';

const Minors = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1}></Grid>

				<Grid item sm={10} md={4} lg={3}>
					<h2 className={classes.header}>{content.title}</h2>
				</Grid>

				<Grid item sm={1} md={1} lg={2}></Grid>

				<Grid item sm={1} className={classes.tabletColumn}></Grid>

				<Grid item sm={10} md={5} className={classes.contentColumn}>
					<p className={classes.description}>{content.description}</p>
				</Grid>

				<Grid item sm={1}></Grid>
			</Grid>
		</section>
	);
};

export default Minors;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		marginTop: '4em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	header: {
		fontSize: smallSubtitle.fontSize,
		lineHeight: smallSubtitle.lineHeight,
		margin: '0.75em 0',
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
		},
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	contentColumn: {
		paddingTop: '0.5em',
		'@media (max-width: 599px)': {
			paddingTop: 0,
		},
	},
	tabletColumn: {
		'@media(min-width: 960px)': {
			display: 'none',
		},
	},
});
