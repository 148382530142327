import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../content/privacy/intro.json';
import { defaultSectionWidth, pageSubtitle, smallSubtitle } from '../../../styles/dimensions';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';

const Intro = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container classes={{ root: classes.grid }}>
				<Grid item sm={1}></Grid>

				<Grid item xs={12} sm={10} md={8} lg={6}>
					<h1 className={classes.header}>{content.title}</h1>
					{content.paragraphs.map((item, key) => (
						<p key={key} className={classes.content}>
							{item}
						</p>
					))}
				</Grid>

				<Grid item sm={1} md={3} lg={5}></Grid>
			</Grid>

            <hr className={classes.divider} />
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingTop: 'calc(7.625em + 2px + 3.5em)',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: 'calc(3.75em + 2px + 3.5em)',
		},
	},
	grid: {
		marginBottom: '4.75em',
		'@media (max-width: 599px)': {
			marginBottom: '2.5em',
		},
	},
	header: {
		fontFamily: RigrafExpanded.semiBold,
		fontSize: pageSubtitle.fontSize,
        lineHeight: pageSubtitle.lineHeight,
        marginTop: 0,
		marginBottom: '0.7em',
		'@media (max-width: 599px)': {
			fontSize: smallSubtitle.fontSize,
			lineHeight: smallSubtitle.lineHeight,
		},
	},
	content: {
		fontFamily: MetroSans.book,
		color: colors.textGrey,
    },
    divider: {
        borderColor: colors.lightDividerGray,
        borderBottom: 0,
        borderStyle: 'solid',
    },
});
