import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import content from '../../../content/privacy/collecting.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import colors from '../../../styles/colors';
import { defaultSectionWidth, largeText, regularText, smallSubtitle, smallText } from '../../../styles/dimensions';
import DropDown from '../../../images/privacy/dropDown';
import { useScrollContext } from '../../../hooks/useScrollContext';

const Collecting = () => {
	const scroll = useScrollContext();

	const classes = useClasses();

	const handleAccordionChange = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1}></Grid>

				<Grid item sm={10} md={4} lg={3}>
					<h2 className={classes.header}>{content.title}</h2>
				</Grid>

				<Grid item sm={1} md={1} lg={2}></Grid>

				<Grid item sm={1} className={classes.tabletColumn}></Grid>

				<Grid item sm={10} md={5}>
					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.types.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							<div className={classes.container}>
								<h4 className={classes.innerHeader}>{content.types.personalData.title}</h4>
								<p className={classes.descriptionWithMargin}>
									{content.types.personalData.description}
								</p>
								<ul>
									{content.types.personalData.listItems.map((item, key) => (
										<li key={key} className={classes.description}>
											{item}
										</li>
									))}
								</ul>
							</div>

							<div className={classes.container}>
								<h4 className={classes.innerHeader}>{content.types.usageData.title}</h4>
								<ul className={classes.list}>
									{content.types.usageData.paragraphs.map((item, key) => (
										<li key={key} className={classes.listItem}>
											<span className={classes.description}>{item}</span>
										</li>
									))}
								</ul>
							</div>

							<div>
								<h4 className={classes.innerHeader}>{content.types.tracking.title}</h4>
								<p className={classes.descriptionWithMargin}>
									{content.types.tracking.upperDescription}
								</p>
								<ul className={classes.list}>
									{content.types.tracking.upperListItems.map((item, key) => (
										<li key={key} className={classes.listItem}>
											<h6 className={classes.listHeader}>{item.title}</h6>
											<span className={classes.description}>{item.description}</span>
											{item.link && (
												<a
													href={item.link.path}
													target="_blank"
													rel="noopener norefferer nofollow"
													role="link"
													className={classes.link}>
													{item.link.description}
												</a>
											)}
										</li>
									))}
								</ul>

								<p className={classes.descriptionWithMargin}>
									{content.types.tracking.middleDescription}
									<a
										href={content.types.tracking.middleDescriptionLink.path}
										target="_blank"
										rel="noopener norefferer nofollow"
										role="link"
										className={classes.link}>
										{content.types.tracking.middleDescriptionLink.description}
									</a>
								</p>
								<p className={classes.descriptionWithMargin}>
									{content.types.tracking.lowerDescription}
								</p>
							</div>

							<div>
								{content.types.tracking.lowerListItems.map((item, key) => (
									<div key={key}>
										<h6 className={classes.listTopHeader}>{item.title}</h6>
										<ul className={classes.list}>
											{item.innerList.map((listItem, i) => (
												<li key={i} className={`${classes.description} ${classes.listItem}`}>
													{listItem}
												</li>
											))}
										</ul>
									</div>
								))}
							</div>
						</AccordionDetails>
					</Accordion>

					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.useOfPersonalData.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							{content.useOfPersonalData.uses.map((use, key) => (
								<div key={key}>
									<p className={classes.description}>{use.description}</p>
									<ul className={classes.list}>
										{use.listItems.map((item, i) => (
											<li key={i} className={classes.listItem}>
												<h6 className={classes.listHeader}>{item.title}</h6>
												<span className={classes.description}>{item.description}</span>
											</li>
										))}
									</ul>
								</div>
							))}
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid item sm={1}></Grid>
			</Grid>
		</section>
	);
};

export default Collecting;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		marginTop: '4em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	header: {
		fontSize: smallSubtitle.fontSize,
		lineHeight: smallSubtitle.lineHeight,
		margin: '0.75em 0',
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
		},
	},
	subheader: {
		fontSize: regularText.fontSize,
		lineHeight: regularText.lineHeight,
		margin: 0,
	},
	innerHeader: {
		fontFamily: Rigraf.semiBold,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: 0,
	},
	listHeader: {
		fontFamily: MetroSans.regular,
		fontWeight: 'bold',
		display: 'inline',
		margin: 0,
	},
	listTopHeader: {
		marginTop: '1.25em',
		marginBottom: 0,
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: 0,
	},
	descriptionWithMargin: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: '0.625em 0',
	},
	accordionRoot: {
		boxShadow: `0px 2px 1px -1px ${colors.lightDividerGray}`,
	},
	accordionSummaryRoot: {
		padding: 0,
	},
	accordionSummaryContent: {
		margin: '1.5em 0',
	},
	accordionDetailsRoot: {
		padding: 0,
		paddingBottom: '1.5em',
		flexDirection: 'column',
	},
	list: {
		margin: 0,
	},
	listItem: {
		margin: '0.75em 0',
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	link: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		textDecoration: 'underline',
	},
	container: {
		marginBottom: '1em',
	},
	tabletColumn: {
		'@media(min-width: 960px)': {
			display: 'none',
		},
	},
});
