import { Accordion, Grid, makeStyles, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { useCallback } from 'react';
import content from '../../../content/privacy/interpretation.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { defaultSectionWidth, largeText, regularText, smallSubtitle, smallText } from '../../../styles/dimensions';
import DropDown from '../../../images/privacy/dropDown';
import colors from '../../../styles/colors';
import { useScrollContext } from '../../../hooks/useScrollContext';

const Interpretation = () => {
	const scroll = useScrollContext();

	const classes = useClasses();

	const handleAccordionChange = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1}></Grid>

				<Grid item sm={10} md={4} lg={3}>
					<h2 className={classes.header}>{content.title}</h2>
				</Grid>

				<Grid item sm={1} md={1} lg={2}></Grid>

				<Grid item sm={1} className={classes.tabletColumn}></Grid>

				<Grid item sm={10} md={5}>
					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.interpretation.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							<p className={classes.description}>{content.interpretation.description}</p>
						</AccordionDetails>
					</Accordion>

					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.definitions.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							<p className={classes.description}>{content.definitions.description}</p>
							<ul className={classes.list}>
								{content.definitions.listItems.map((item, key) => (
									<li key={key} className={classes.listItem}>
										<h6 className={classes.listHeader}>{item.title}</h6>
										<span className={classes.description}>{item.description}</span>
										{item.link && (
											<a
												href={item.link.path}
												target="_blank"
												rel="noopener"
												role="link"
												className={classes.link}>
												{item.link.description}
											</a>
										)}
									</li>
								))}
							</ul>
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid item sm={1}></Grid>
			</Grid>
		</section>
	);
};

export default Interpretation;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		marginTop: '2.5em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	header: {
		fontSize: smallSubtitle.fontSize,
		lineHeight: smallSubtitle.lineHeight,
		margin: '0.75em 0',
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
		},
	},
	subheader: {
		fontSize: regularText.fontSize,
		lineHeight: regularText.lineHeight,
		margin: 0,
	},
	listHeader: {
		fontFamily: MetroSans.regular,
		fontWeight: 'bold',
		display: 'inline',
		margin: 0,
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: 0,
	},
	link: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		textDecoration: 'underline',
	},
	accordionRoot: {
		boxShadow: `0px 2px 1px -1px ${colors.lightDividerGray}`,
	},
	accordionSummaryRoot: {
		padding: 0,
	},
	accordionSummaryContent: {
		margin: '1.5em 0',
	},
	accordionDetailsRoot: {
		padding: 0,
		paddingBottom: '1.5em',
		flexDirection: 'column',
	},
	list: {
		margin: 0,
	},
	listItem: {
		margin: '0.75em 0',
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	tabletColumn: {
		'@media(min-width: 960px)': {
			display: 'none',
		},
	},
});
