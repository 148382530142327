import Layout from '../components/layout';
import Changes from '../components/sections/privacy/changes';
import Collecting from '../components/sections/privacy/collecting';
import Contact from '../components/sections/privacy/contact';
import Interpretation from '../components/sections/privacy/interpretation';
import Intro from '../components/sections/privacy/intro';
import Links from '../components/sections/privacy/links';
import Minors from '../components/sections/privacy/minors';
import Rights from '../components/sections/privacy/rights';
import SEO from '../components/seo';
import { descriptions, titles } from '../consts/seo';

const PrivacyPolicy = () => (
	<Layout>
		<SEO title={titles.privacyPolicy} description={descriptions.privacyPolicy} />

		<Intro />
		<Interpretation />
		<Collecting />
		<Rights />
		<Minors />
		<Links />
		<Changes />
		<Contact />
	</Layout>
);

export default PrivacyPolicy;
