import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import content from '../../../content/privacy/rights.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { useScrollContext } from '../../../hooks/useScrollContext';
import DropDown from '../../../images/privacy/dropDown';
import colors from '../../../styles/colors';
import { defaultSectionWidth, largeText, regularText, smallSubtitle, smallText } from '../../../styles/dimensions';

const Rights = () => {
	const scroll = useScrollContext();

	const classes = useClasses();

	const handleAccordionChange = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1}></Grid>

				<Grid item sm={10} md={4} lg={3}>
					<h2 className={classes.header}>{content.title}</h2>
				</Grid>

				<Grid item sm={1} md={1} lg={2}></Grid>

				<Grid item sm={1} className={classes.tabletColumn}></Grid>

				<Grid item sm={10} md={5}>
					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.about.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							<p className={classes.description}>{content.about.description}</p>
							<ul className={classes.list}>
								{content.about.listItems.map((item, key) => (
									<li key={key} className={classes.listItem}>
										<h6 className={classes.listHeader}>{item.title}</h6>
										<span className={classes.description}>{item.description}</span>
									</li>
								))}
							</ul>

							<p className={classes.descriptionWithMargin}>
								{content.contact.description}{' '}
								<a href={`mailto:${content.contact.link.path}`} className={classes.link}>
									{content.contact.link.description}
								</a>
							</p>

							{content.paragraphs.map((item, key) => (
								<p key={key} className={classes.descriptionWithMargin}>
									{item}
								</p>
							))}
						</AccordionDetails>
					</Accordion>

					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.retention.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							{content.retention.paragraphs.map((item, key) => (
								<p key={key} className={classes.descriptionWithMargin}>
									{item}
								</p>
							))}
						</AccordionDetails>
					</Accordion>

					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.transfer.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							{content.transfer.paragraphs.map((item, key) => (
								<p key={key} className={classes.descriptionWithMargin}>
									{item}
								</p>
							))}
						</AccordionDetails>
					</Accordion>

					<Accordion
						classes={{ root: classes.accordionRoot }}
						TransitionProps={{ onEntered: handleAccordionChange, onExited: handleAccordionChange }}>
						<AccordionSummary
							expandIcon={<DropDown />}
							classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
							<h3 className={classes.subheader}>{content.disclosure.title}</h3>
						</AccordionSummary>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							{content.disclosure.items.map((item, key) => (
								<div key={key}>
									<h6 className={classes.listTopHeader}>{item.title}</h6>
									<p className={classes.descriptionWithMargin}>{item.description}</p>
									{item.listItems && (
										<ul className={classes.list}>
											{item.listItems.map((listItem, i) => (
												<li key={i} className={classes.listItemDash}>
													<span className={classes.description}>{listItem}</span>
												</li>
											))}
										</ul>
									)}
								</div>
							))}
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid item sm={1}></Grid>
			</Grid>
		</section>
	);
};

export default Rights;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		marginTop: '4em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	header: {
		fontSize: smallSubtitle.fontSize,
		lineHeight: smallSubtitle.lineHeight,
		margin: '0.75em 0',
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
		},
	},
	subheader: {
		fontSize: regularText.fontSize,
		lineHeight: regularText.lineHeight,
		margin: 0,
	},
	listTopHeader: {
		marginTop: '1.25em',
		marginBottom: 0,
	},
	listHeader: {
		fontFamily: MetroSans.regular,
		fontWeight: 'bold',
		display: 'inline',
		margin: 0,
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: 0,
	},
	descriptionWithMargin: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: '0.625em 0',
	},
	accordionRoot: {
		boxShadow: `0px 2px 1px -1px ${colors.lightDividerGray}`,
	},
	accordionSummaryRoot: {
		padding: 0,
	},
	accordionSummaryContent: {
		margin: '1.5em 0',
	},
	accordionDetailsRoot: {
		padding: 0,
		paddingBottom: '1.5em',
		flexDirection: 'column',
	},
	list: {
		margin: 0,
	},
	listItem: {
		margin: '0.75em 0',
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	link: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		textDecoration: 'underline',
	},
	listItemDash: {
		'&:before': {
			content: "'— '",
			position: 'absolute',
			marginLeft: '-1.5em',
		},
	},
	tabletColumn: {
		'@media(min-width: 960px)': {
			display: 'none',
		},
	},
});
